import React from 'react'

import Layout from '../components/Layout/layout-single'
import Linklist from '../components/Linklist/list'
import SEO from '../components/SEO/seo'
import Header from '../components/Header/header'

class NotFoundPage extends React.Component {
  render () {
    const { data } = this.props
    const listRows = [
      { title: 'Welcome', url: '/', iframe: true, layer: '1' },
      { title: 'Projects', url: '/project', iframe: true, layer: '2' },
      { title: 'Blog', url: '/blog', iframe: true, layer: '2' },
      { title: 'Museum', url: '/all', iframe: true, layer: '2' }
    ]

    return (
      <Layout>
        <>
          <SEO title="404: Not found" imagefile="404" location={this.props.location} />
          <Header
            location={this.props.location}
            alt="404: Not found"
            title="404: Not found"
          />
        </>
        <>
          <h1>Oops!</h1>
          <p>Maybe one of these pages is the page you are looking for...</p>
          <Linklist items={listRows}/>
        </>
      </Layout>
    )
  }
}

export default NotFoundPage
